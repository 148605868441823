<template>
  <list-title-bar :titleArray="titleArray"></list-title-bar>
</template>

<script>
import ListTitleBar from "@c/operation-log/common/paging-list/list-title-bar.vue";

export default {
  components: { ListTitleBar },

  computed: {
    titleArray() {
      return [
        { name: "姓名", percent: "10%" },
        { name: "氧减指数", percent: "10%" },
        { name: "设备SN", percent: "20%" },
        { name: "开始时间", percent: "15%" },
        { name: "监测时长", percent: "15%" },
        { name: "机构", percent: "20%" },
        { name: "操作", percent: "10%" },
      ];
    },
  },
};
</script>
