<template>
  <list-cell :isSelected="isSelected" @onSelectClick="onSelectClick">
    <div class="system-cell">
      <cell-unit :style="{ width: '10%' }" :title="`${name}`"></cell-unit>
      <cell-unit
        :style="{ width: '10%' }"
        :title="oxygenMinusInfo"
        :color="oxygenMinusColor"
      ></cell-unit>
      <cell-unit :style="{ width: '20%' }" :title="sn"></cell-unit>
      <cell-unit :style="{ width: '15%' }" :title="startTimeStr"></cell-unit>
      <cell-unit :style="{ width: '15%' }" :title="durationStr"></cell-unit>
      <cell-unit :style="{ width: '20%' }" :title="organization"></cell-unit>
      <div
        class="breath-cell-button-container"
        :style="{ width: '10%', cursor: 'pointer' }"
      >
        <div class="breath-cell-button-container-content">
          <cell-unit
            color="#325FAC"
            :icon="require('@imgs/report-preview.png')"
            title="查看"
            @onClick="onCheckDetailClick"
          ></cell-unit>
        </div>
      </div>
    </div>
  </list-cell>
</template>

<script>
import CellUnit from "@c/operation-log/common/paging-list/cell-unit.vue";
import ListCell from "@c/operation-log/common/paging-list/list-cell.vue";
import { ReportPreviewTool } from "@js/report-preview-tool.js";
import { CacheTool } from "@js/cache-tool.js";
export default {
  components: {
    ListCell,
    CellUnit,
  },

  props: {
    index: Number,
    model: Object,
    isSelected: Boolean,
    showDownIndexLevel: Boolean,
  },

  computed: {
    userInfoUpdate() {
      return !!this.model.userInfoUpdate;
    },

    name() {
      if (this.model.name) {
        if (this.model.userInfoUpdate) {
          return `${this.model.name}（编辑）`;
        } else {
          return `${this.model.name}`;
        }
      } else {
        return placeholderText(null);
      }
    },

    oxygenMinusInfo() {
      if (this.showDownIndexLevel) {
        return `${placeholderText(this.model.downIndex)}（${placeholderText(
          this.model.downIndexLv
        )}）`;
      } else {
        return placeholderText(this.model.downIndex);
      }
    },

    oxygenMinusColor() {
      if (this.showDownIndexLevel) {
        switch (this.model.downIndexLv) {
          case "正常":
            return "#6CC291";
          case "轻度":
            return "#325FAC";
          case "中度":
            return "#F39920";
          case "重度":
            return "#F45C50";
          default:
            break;
        }
      } else {
        return "#333333";
      }
    },

    sn() {
      const sn = placeholderText(this.model.sn);
      const deviceNumber = placeholderText(this.model.deviceNumber);
      return `${sn}（${deviceNumber}）`;
    },

    startTimeStr() {
      return placeholderText(this.model.startAt);
    },

    durationStr() {
      return placeholderText(this.model.detectionDuration);
    },

    organization() {
      return placeholderText(this.model.hospitalName);
    },
  },

  methods: {
    onSelectClick: function () {
      this.$emit("onSelectClick", this.model);
    },

    onCheckDetailClick() {
      const loginInfo = CacheTool.getLoginInfo();
      //传入token、uid防止安卓取不到（后期可能去掉）
      ReportPreviewTool.previewBloodOxygenReport(
        this.model.id
        // loginInfo.token,
        // loginInfo.uid
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.system-cell {
  height: 100%;
  display: flex;
  align-items: center;
}

.slot {
  color: #666666;
  font-size: 14px;

  &-check {
    color: #325fac;
    border-bottom: 1px solid #325fac;
  }
}
</style>
